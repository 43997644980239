import { addLocaleData } from 'react-intl';

import 'moment/locale/pt';
import antdLocale from 'antd/lib/locale-provider/pt_PT';
import intlLocale from 'react-intl/locale-data/pt';
import messages from './messages.po';

addLocaleData(intlLocale);

export default { antdLocale, intlLocale, messages };
